export const SkillsData = [
  {
    id: 1,
    name: 'ReactJs',
    icon: 'reactIcon',
  },
  {
    id: 29,
    name: 'VueJs',
    icon: 'vueIcon',
  },
  {
    id: 25,
    name: 'Sass',
    icon: 'sassIcon',
  },
  {
    id: 2,
    name: 'Tailwind CSS',
    icon: 'tailwindIcon',
  },
  {
    id: 22,
    name: 'NodeJs',
    icon: 'nodeIcon',
  },
  {
    id: 3,
    name: 'MongoDB',
    icon: 'mongodbIcon',
  },
  {
    id: 6,
    name: 'HTML',
    icon: 'htmlIcon',
  },
  {
    id: 5,
    name: 'CSS',
    icon: 'cssIcon',
  },
  {
    id: 4,
    name: 'JavaScript',
    icon: 'jsIcon',
  },
  // {
  //   id: 24,
  //   name: 'Wordpress',
  //   icon: 'wordpressIcon',
  // },
  // MySql

  // tableau
  {
    id: 28,
    name: 'Tableau',
    icon: 'tableauIcon',
  },
  {
    id: 27,
    name: 'Power BI',
    icon: 'powerbiIcon',
  },
  {
    id: 26,
    name: 'MySql',
    icon: 'mysqlIcon',
  },
  {
    id: 20,
    name: 'Git',
    icon: 'gitIcon',
  },
  {
    id: 21,
    name: 'Github',
    icon: 'githubIcon',
  },
  {
    id: 30,
    name: 'Gitlab',
    icon: 'gitlabIcon',
  },
  {
    id: 23,
    name: 'AWS',
    icon: 'awsIcon',
  },
  {
    id: 7,
    name: 'C++',
    icon: 'cplusplusIcon',
  },
  {
    id: 8,
    name: 'C sharp',
    icon: 'csharpIcon',
  },
  {
    id: 9,
    name: 'Java',
    icon: 'javaIcon',
  },
  {
    id: 10,
    name: 'python',
    icon: 'pythonIcon',
  },
  // {
  //   id: 11,
  //   name: 'firebase',
  //   icon: 'firebaseIcon',
  // },
  {
    id: 12,
    name: 'dotnet',
    icon: 'dotnetIcon',
  },
  // {
  //   id: 13,
  //   name: 'NextJs',
  //   icon: 'nextIcon',
  // },
  {
    id: 14,
    name: 'flask',
    icon: 'flaskIcon',
  },
  {
    id: 15,
    name: 'scikit-learn',
    icon: 'sklearnIcon',
  },
  // {
  //   id: 16,
  //   name: 'Blender',
  //   icon: 'blenderIcon',
  // },
  // {
  //   id: 17,
  //   name: 'Unity',
  //   icon: 'unityIcon',
  // },
  {
    id: 18,
    name: 'Linux',
    icon: 'linuxIcon',
  },
  // {
  //   id: 19,
  //   name: 'hadoop',
  //   icon: 'hadoopIcon',
  // },
  // vue
  // gitlab
];
