import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { TiTickOutline } from 'react-icons/ti';
import { shakeInputs } from '../utilites/Animation';

export default function Form() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [sendingStatus, setSendingStatus] = useState('idle'); // States: idle, sending, sent, error
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (sendingStatus === 'sent' || sendingStatus === 'error') {
      const timer = setTimeout(() => {
        setSendingStatus('idle');
        setIsSuccess(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [sendingStatus]);

  // Validates the form fields and sets errors state accordingly.
  const validateForm = () => {
    const { name, email, subject, message } = formData;
    const newErrors = {};

    if (!subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      setSendingStatus('sending');
      try {
        const templateParams = {
          name: formData.name,
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
        };
        await emailjs.send(
          'service_ke8p12p',
          'template_wfcxwr5',
          templateParams,
          '-84BNYXL46mFyX9if'
        );
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
        setIsSuccess(true);
        setSendingStatus('sent');
      } catch (error) {
        console.error('Email could not be sent:', error);
        alert('Message could not be sent!');
        setIsSuccess(false);
        setSendingStatus('error');
      }
    } else {
      shakeInputs();
    }
  };

  // Handles change in input fields and updates the form data state.
  const handleChange = (e) => {
    setIsSuccess(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const inputClass = 'border w-full border-gray-400 text-black p-2 rounded-md';

  let buttonText;
  switch (sendingStatus) {
    case 'sending':
      buttonText = 'Sending...';
      break;
    case 'sent':
      buttonText = 'Sent';
      break;
    case 'error':
      buttonText = 'Error. Try again';
      break;
    default:
      buttonText = 'Send';
  }

  return (
    <form
      className="flex flex-col p-6 items-center justify-center gap-3 md:w-[400px] rounded-md bg-[#2c2a2a]"
      onSubmit={handleSubmit}
    >
      <h1 className="py-2 text-xl font-bold ">Write Me</h1>
      {isSuccess ? (
        <span className="flex items-center gap-1 text-green-500">
          <TiTickOutline />
          Message sent successfully
        </span>
      ) : (
        <h2 className="pb-2 text-justify text-lg">
          Feel free to contact me. I would Love to hear from you!
        </h2>
      )}

      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        className={`${inputClass} ${
          errors.name && 'border-red-500 animate-shake'
        }`}
        placeholder="Name"
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        className={`${inputClass} ${
          errors.email && 'border-red-500 animate-shake'
        }`}
        placeholder="Email"
      />
      <input
        type="text"
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        className={`${inputClass} ${
          errors.subject && 'border-red-500 animate-shake'
        }`}
        placeholder="Subject"
      />
      <textarea
        name="message"
        value={formData.message}
        onChange={handleChange}
        rows="6"
        cols="50"
        className={`resize-none  ${inputClass} ${
          errors.message && 'border-red-600 animate-shake'
        } `}
        placeholder="Message"
      />
      <button
        type="submit"
        className="flex w-40 bg-white p-2 justify-center gap-3 mb-3 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow focus:outline-none "
        disabled={sendingStatus === 'sending'}
      >
        {buttonText}
      </button>
    </form>
  );
}
