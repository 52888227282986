import React, { useState } from 'react';
import BelowToUp from '../motions/BelowToUp';
// import ProjectItem from '../components/ProjectItem';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ProjectCard from '../components/ProjectCard';

function Projects(props) {
  const [projects] = useState(props.projects);
  const projectList = projects.map((project) => (
    // <ProjectItem
    //   id={project.id}
    //   key={project.id}
    //   name={project.name}
    //   imgSrc={project.imgSrc}
    //   description={project.description}
    //   codeLink={project.codeLink}
    //   onlineLink={project.onlineLink}
    //   childComponentTypes={project.childComponentTypes}
    // />
    <ProjectCard
      id={project.id}
      key={project.id}
      name={project.name}
      imgSrc={project.imgSrc}
      description={project.description}
      codeLink={project.codeLink}
      onlineLink={project.onlineLink}
      childComponentTypes={project.childComponentTypes}
      projectDuration={project.projectDuration}
      teamSize={project.teamSize}
      role={project.role}
    />
  ));

  const chunkArray = (myArray, chunkSize) => {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunkSize));
    }
    return results;
  };

  const chunkedProjectList = chunkArray(projectList, 4);

  return (
    <div className="px-2 max-w-[1256px] mx-auto">
      <h1 className="py-2 md:text-4xl text-xl text-gray-500 font-semibold">
        Projects
      </h1>
      <BelowToUp>
        <Carousel
          showStatus={false}
          // swipeable={false}
          showThumbs={false}
          // showArrows={false}
          emulateTouch={true}
        >
          {chunkedProjectList.map((chunk, index) => (
            <div
              key={index}
              className="flex md:flex-row flex-col flex-wrap justify-center p-8 md:p-2 mb-10 md:mx-10 gap-10"
            >
              {chunk}
            </div>
          ))}
        </Carousel>

        {/* <ProjectCard /> */}
      </BelowToUp>
    </div>
  );
}

export default Projects;
