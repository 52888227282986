import React, { useState } from 'react';
import SkillsItems from '../components/SkillsItems';
import BelowToUp from '../motions/BelowToUp';

export default function Skills(props) {
  const [skills, setSkills] = useState(props.skills);
  const SkillsList = skills.map((skill) => (
    <SkillsItems
      id={skill.id}
      key={skill.id}
      name={skill.name}
      icon={skill.icon}
    />
  ));
  return (
    <div className="max-w-[1256px] items-center justify-center mx-auto md:h-screen px-2">
      <BelowToUp>
        <div>
          <h1 className="py-2 md:text-4xl text-xl text-gray-500 font-semibold">
            Skills
          </h1>
          <div className="bg-custom-radial-gradient grid grid-cols-4 md:grid-cols-5 mx-auto lg:grid-cols-6 w-fit">
            {SkillsList}
          </div>
        </div>
        {/* <div className="md:flex grid items-center justify-between">
        </div> */}
      </BelowToUp>
    </div>
  );
}
