export const shakeInputs = () => {
  const inputs = document.querySelectorAll(
    'input[type="text"], input[type="email"], textarea'
  );
  inputs.forEach((input) => {
    if (input.value.trim() === '') {
      input.classList.add('animate-shake');
      setTimeout(() => {
        input.classList.remove('animate-shake');
      }, 500);
    }
  });
};
