import React from 'react';
import { copyrightsTxt } from '../content/Texts';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FooterBox = () => {
  const { t } = useTranslation();
  return (
    <footer className="text-center bg-[#2c2a2a] grid p-2 md:p-16">
      <div className="box-border flex flex-col md:w-[1256px] mx-auto">
        <div className="md:flex  gap-2 justify-evenly mb-10 text-xl">
          {/* get In touch */}
          <div className="flex flex-col items-start gap-3">
            <span className="text-2xl text-[#686666] uppercase">
              {t('get_in_touch')}
            </span>
            <span className="w-fit text-center">
              <a href="mailto:prshnt.extrao1@gmail.com">
                prshnt.extrao1@gmail.com
              </a>
            </span>
            <span className="w-fit text-center">
              <a href="https://discord.com/users/663046650838908940">
                discord/@prshntbhushal
              </a>
            </span>
            <span className="w-fit text-center">
              <a href="https://twitter.com/__prshnt__/">twitter/@__prshnt__</a>
            </span>
            <div className="flex gap-4"></div>
          </div>
          {/* connect */}
          <div className="flex flex-col items-start gap-2">
            <span className="text-2xl text-[#686666] uppercase">
              {t('connect')}
            </span>
            <span className="w-fit text-center">
              <a href="https://www.linkedin.com/in/bhushal-prashant/">
                linkedin/@prshntbhushal
              </a>
            </span>
            <span className="w-fit text-center">
              <a href="https://instagram.com/__prshnt__/">
                instagram/@__prshnt__
              </a>
            </span>
            <span className="w-fit text-center">
              <a href="https://www.github.com/prshnt-bhushal">
                github/@prshnt-bhushal
              </a>
            </span>
            <span className="w-fit text-center">
              <a href="https://medium.com/@prshntbhushal">
                medium/@prshntbhushal
              </a>
            </span>
          </div>
          {/* signature */}
          <div className="flex flex-col md:items-end  md:gap-5">
            <span className="w-fit md:text-5xl text-4xl">{t('my_name')}</span>
            <img
              src="Images/profile/signature.png"
              alt="signature"
              className="md:w-[175px] w-[150px]"
            />
            <span className="py-2 px-6 gap-2 rounded-sm items-center w-fit text-center flex border">
              {t('my_country')}{' '}
              <img
                src="Images/profile/waveFlag.gif"
                alt="signature"
                width="20px"
              />
            </span>
          </div>
        </div>
        <p className="md:border-t md:pt-2">
          &copy; {new Date().getFullYear()} Copyrights {copyrightsTxt}
        </p>
      </div>
    </footer>
  );
};

export default FooterBox;
