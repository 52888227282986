export const typingArray = [
  'Namaste World',
  'Hello World',
  'नमस्ते संसार',
  'Welcome to my ',
  'Domain',
];
export const welcomeTxt = 
  'A passionate computer engineer from Nepal who is incredibly interested in how technology and data interact and how this combination has the power to completely change the way we live. Have similar ideas? Let’s connect!';
export const aboutIntro = `Hi, my name is Prashant Bhushal. As a recent Computer Engineering graduate, I'm a dedicated problem-solver with a strong foundation in software development and data-driven solutions. My passion for technology, combined with hands-on experience, has honed my skills in software development, data analysis, and project management. I love meeting new people and learning about different cultures, as it broadens my perspective. Eager to learn and collaborate. Any Interesting projects? Let's`;

export const joke = `Why did the programmer create a "Contact Me" form on their website? Because they didn't want bugs to be the only things that get reported!`;

export const copyrightsTxt = '. All rights reserved. ';

export const extraas =
  "I love meeting new people and learning about different cultures, as it broadens my perspective, This enduring fascination is what drives my passion and keeps me moving forward in my mission to harness data's revolutionary power and create a better tomorrow for future generations.";
