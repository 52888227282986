import React, { useState } from 'react';
import './components_css/CourseTimeline.css';
import LeftToRight from '../motions/LeftToRight';
import { Link } from 'react-router-dom';

function CourseTimeline(props) {
  const [timelineItems] = useState(props.timeline);
  const timeLineList = timelineItems.map((item, index) => (
    <LeftToRight key={index}>
      <Link to={item.link} target="_blank">
        <li className={`relative group transition w-80`}>
          <div
            className={`flex  absolute transition flex-col duration-500 hover:bg-black hover:bg-opacity-50 rounded-lg  p-5 cursor-pointer ${
              index % 2 === 0 ? 'top-[-230px]' : ''
            }`}
          >
            <div className="icon">{item.icon}</div>
            <div className="p-3">
              <span className="font-semibold">{item.date}</span>
              <p className="text-[#0f0] text-sm">{item.status}</p>
              <span className="text-white text-lg">{item.description}</span>
            </div>
          </div>
        </li>
      </Link>
    </LeftToRight>
  ));

  return (
    <div className="max-w-[1256px] hidden h-screen md:grid mx-auto justify-center">
      <h1 className="py-2 text-gray-500 md:text-4xl text-xl font-semibold w-fit">
        Experiences & Certifications
      </h1>
      <div className="timeline flex mx-auto">
        <ul className="flex flex-col md:flex-row py-[5px] md:border-t-2 md:rotate-0 -rotate-90 md:border-l-0 border-l-2 border-white ">
          {/* {timelineItems.map((item, index) => (
            <LeftToRight key={index}>
              <Link to={item.link} target="_blank">
                <li className={`relative group transition w-80`}>
                  <div
                    className={`flex  absolute transition flex-col duration-500 hover:bg-black hover:bg-opacity-50 rounded-lg  p-5 cursor-pointer ${
                      index % 2 === 0 ? 'top-[-230px]' : ''
                    }`}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className="p-3">
                      <span className="font-semibold">{item.date}</span>
                      <p className="text-[#0f0] text-sm">{item.status}</p>
                      <span className="text-white text-lg">
                        {item.description}
                      </span>
                    </div>
                  </div>
                </li>
              </Link>
            </LeftToRight>
          ))} */}
          {timeLineList}
        </ul>
      </div>
    </div>
  );
}

export default CourseTimeline;
