import { FaAws, FaGoogle } from 'react-icons/fa';
import { GiGraduateCap } from 'react-icons/gi';
import { FaIdCard } from 'react-icons/fa6';

export const timelineItems = [
  {
    link: 'https://www.coursera.org/google-certificates/data-analytics-certificate',
    icon: <FaGoogle size={48} />,
    date: '',
    status: 'Ongoing',
    description: 'Google Data Analytics Professional Certificate',
  },
  {
    link: 'https://www.inctic.io',
    icon: <FaIdCard size={48} />,
    date: '5th March 2024',
    status: 'Completed',
    description: 'Inctic Labs - Software Engineering Internship',
  },
  {
    link: 'https://ncit.edu.np/courses/be-computer',
    icon: <GiGraduateCap size={48} />,
    date: '10th September 2023',
    status: 'Completed',
    description: 'Nepal College of Information Technology - BE Computer',
  },
  {
    link: 'https://www.credly.com/go/c2nZnegN',
    icon: <FaAws size={48} />,
    date: '23rd July 2022',
    status: 'Completed',
    description: 'AWS Academy Graduate - AWS Academy Data Analytics',
  },
];
