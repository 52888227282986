import React from 'react';
import { aboutIntro } from '../content/Texts';
import BelowToUp from '../motions/BelowToUp';
import LeftToRight from '../motions/LeftToRight';
import RightToLeft from '../motions/RightToLeft';

const About = () => {
  return (
    <BelowToUp>
      <div className="max-w-[1256px] h-screen mx-auto">
        <h1 className="p-2 md:text-4xl text-xl text-[#2e2c2c] font-semibold">
          About Me
        </h1>
        <div className="justify-between h-fit md:h-full items-center md:flex-row flex flex-col-reverse px-2">
          {/* Introduction */}
          <LeftToRight>
            <div className="flex md:flex-col items-center w-[300px] h-[200px] md:w-full md:h-fit m-1">
              <div className="profile-picture w-[300px] md:w-[400px] aspect-[1/1]">
                <div id="img3d" className="rounded-3xl">
                  <img src="/Images/profile/profileCutOut.png" alt="profile" />
                </div>
              </div>
              <div className="text-center h-fit rotate-[270deg] md:rotate-0 ">
                <h1 className="md:text-2xl text-lg font-bold">
                  Prashant Bhushal
                </h1>
                <h2 className="md:text-xl text-md font-semibold">
                  Computer Engineer | Data Analyst
                </h2>
              </div>
            </div>
          </LeftToRight>
          {/* profile */}
          <RightToLeft>
            <div className="flex h-fit flex-col gap-5">
              <h1 className="md:text-3xl text-xl font-bold">Introduction</h1>
              <p className="text-justify md:text-lg lg:w-[750px]">
                {aboutIntro}{' '}
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    document
                      .getElementById('contact')
                      .scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  talk!
                </span>
              </p>
              <LeftToRight>
                <div className="p-2 md:block gap-4 hidden">
                  <span>
                    Also, Feel free to read my blogs on{' '}
                    <a
                      className="text-[#32617d] hover:text-[#304f61] font-bold duration-500"
                      href="https://medium.com/@prshntbhushal"
                      target="blank"
                    >
                      medium/@prshntbhushal
                    </a>
                  </span>
                </div>
              </LeftToRight>
            </div>
          </RightToLeft>
        </div>
      </div>
    </BelowToUp>
  );
};

export default About;
