import React from 'react';
import './components_css/Card.css';

const Card = () => {
  return (
    <div className="flip-card md:w-96 md:h-96 w-72 h-72 cursor-pointer ">
      <div className="flip-card-inner">
        <div className="flip-card-front absolute flex  flex-col justify-center w-full h-full">
          <img src="/Images/profile/profile.png" alt="profile" />
        </div>
        <div className="flip-card-back absolute flex  flex-col justify-center w-full h-full">
          <img src="/Images/profile/ProfileBack.png" alt="profile" />
        </div>
      </div>
    </div>
  );
};

export default Card;
