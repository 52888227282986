import React, { useEffect } from 'react';
import { welcomeTxt } from '../content/Texts';
import { SiGmail } from 'react-icons/si';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { GiHand } from 'react-icons/gi';
import { FaMedium } from 'react-icons/fa6';
import Typing from '../components/Typing';
import BelowToUp from '../motions/BelowToUp';
import LeftToRight from '../motions/LeftToRight';
import { Link } from 'react-router-dom';
import Card from '../components/Card';
import { CiGlobe } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export default function Home() {
  const { t } = useTranslation();
  const languages = [
    {
      name: t('lang_en'),
      code: 'en',
      country_code: 'gb',
    },
    {
      name: t('lang_np'),
      code: 'np',
      country_code: 'np',
    },
  ];

  useEffect(() => {
    document.title = t('my_name');
  }, [t]);
  return (
    <div className="md:h-screen grid content-between">
      <Link to="/">
        <img
          src="/Images/profile/logo2.png"
          alt="Logo For Portfolio"
          width={56}
        />
      </Link>
      {/* dropdown for multi-language */}
      <div className="md:absolute right-24 top-2 flex w-20 items-center text-black m-2">
        <CiGlobe className="md:absolute cursor-pointer right-0" size={18} />
        <select
          id="lang"
          className="cursor-pointer w-full pl-2 bg-transparent appearance-none focus:outline-none"
          value={i18next.language}
          onChange={(event) => {
            const selectedLanguageCode = event.target.value;
            i18next.changeLanguage(selectedLanguageCode);
          }}
        >
          <optgroup label={`${t('select_language')}`}>
            {languages.map(({ code, name, country_code }) => (
              <option key={country_code} value={code}>
                {name}
              </option>
            ))}
          </optgroup>
        </select>
      </div>
      {/* Greeting */}
      <div className="flex md:h-[600px] justify-evenly w-full items-center flex-col-reverse md:flex-row gap-8">
        <div className="md:w-[600px]">
          <h1 className="my-5 md:text-6xl text-3xl px-2 md:w-[420px] text-gray-500">
            <Typing />
          </h1>
          <h1 className="p-2 md:text-5xl text-3xl text-[#2e2c2c] font-semibold h-fit">
            {t('my_name')}
          </h1>
          <h1
            className="p-2 md:text-2xl text-lg font-semibold text-[#304f61]  h-fit"
            onClick={() => {
              document
                .getElementById('skills')
                .scrollIntoView({ behavior: 'smooth' });
            }}
          >
            <span className="hover:text-[#dcf5d8] duration-300 cursor-pointer">
              {t('my_designation')}
            </span>{' '}
            |{' '}
            <span className="hover:text-[#dcf5d8] duration-300 cursor-pointer">
              {t('my_designation_2')}
            </span>
          </h1>
          <p className="p-2 text-lg text-justify items-center self-center text-[#1c2a2d]">
            {t('welcome_txt')}
          </p>
          <BelowToUp>
            <button
              className="bg-[#304f61] flex gap-1 items-center m-2 text-white px-4 p-2 rounded-md hover:bg-[#32617d] duration-500"
              onClick={() => {
                document
                  .getElementById('contact')
                  .scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {t('drop_hello')} <GiHand />
            </button>
          </BelowToUp>
        </div>
        <BelowToUp>
          <Card />
        </BelowToUp>
      </div>
      {/* contact info */}
      <LeftToRight>
        <div className="p-2 flex gap-4">
          <a
            className="hover:text-[#dcf5d8] hover:scale-150 duration-500"
            href="mailto:bhushal.prshnt@gmail.com"
            target="blank"
          >
            <SiGmail size={20} />
          </a>
          <a
            className="hover:text-[#dcf5d8] hover:scale-150 duration-500"
            href="https://www.linkedin.com/in/bhushal-prashant/"
            target="blank"
          >
            <FaLinkedinIn size={20} />
          </a>
          <a
            className="hover:text-[#dcf5d8] hover:scale-150 duration-500"
            href="https://www.github.com/prshnt-bhushal"
            target="blank"
          >
            <FaGithub size={20} />
          </a>
          <a
            className="hover:text-[#dcf5d8] hover:scale-150 duration-500"
            href="https://medium.com/@prshntbhushal"
            target="blank"
          >
            <FaMedium size={20} />
          </a>
        </div>
      </LeftToRight>
    </div>
  );
}
