import React from 'react';
import Form from '../components/Form';
import SocialIcon from '../components/SocialIcon';
import {
  FaDiscord,
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa';
import BelowToUp from '../motions/BelowToUp';
import LeftToRight from '../motions/LeftToRight';

export default function Contact() {
  return (
    <div className="max-w-[1256px] md:h-screen mx-auto w-full items-center px-2">
      <h1 className="py-2 md:text-4xl text-xl text-[#2c2a2a] font-semibold">
        Contact
      </h1>
      <BelowToUp>
        <div className="flex flex-col md:flex-row justify-center md:h-[650px] items-center max-w-[1250px] m-auto p-2">
          <div className="flex bg-[#E8EAED] md:flex-col gap-6 justify-center w-full md:w-fit  md:h-[350px] p-2">
            <SocialIcon
              mediaURL="linkedin.com/in/prashant-bhushal-a1154923b/"
              mediaName="LinkedIn"
            >
              <FaLinkedinIn size={24} />
            </SocialIcon>
            <SocialIcon mediaURL="github.com/prshnt-bhushal" mediaName="GitHub">
              <FaGithub size={24} />
            </SocialIcon>
            <SocialIcon
              mediaURL="instagram.com/__prshnt__/"
              mediaName="Instagram"
            >
              <FaInstagram size={24} />
            </SocialIcon>
            <SocialIcon mediaURL="twitter.com/__prshnt__/" mediaName="Twitter">
              <FaTwitter size={24} />
            </SocialIcon>
            <SocialIcon
              mediaURL="discord.com/users/663046650838908940"
              mediaName="Discord"
            >
              <FaDiscord size={24} />
            </SocialIcon>
          </div>
          <LeftToRight>
            <Form />
          </LeftToRight>
          <div className=" flex flex-col md:h-[350px] bg-white">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d10244.532078003693!2d85.33513275196943!3d27.677990736768113!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1711118090184!5m2!1sen!2snp"
              className="md:w-[600px] w-full h-full"
              title="location"
              style={{ border: '0' }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </BelowToUp>
    </div>
  );
}
