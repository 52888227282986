import React from 'react';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function NoPage() {
  return (
    <section className="h-screen max-w-[1256px] p-2 mx-auto">
      <div className="content flex gap-2 flex-col items-center justify-center h-full">
        <h1 className="md:text-9xl text-7xl text-gray-500 font-bold">404</h1>
        <img src="Images/whiteFlag.gif" alt="white flag" width="200px" />
        <h2 className="md:text-5xl text-2xl text-gray-500 font-semibold">
          Oops! Page Not Found
        </h2>
        <span className="md:text-xl text-md text-justify px-3">
          Sorry, the page you're looking for doesnot exist.
        </span>
        <Link
          to="/"
          className="bg-[#304f61] flex gap-1 items-center mt-2 text-white px-4 py-2 rounded-md hover:bg-[#32617d] duration-500"
        >
          <FaHome size={20} />
          Let's go Home
        </Link>
      </div>
    </section>
  );
}

export default NoPage;
