import React, { useRef, useEffect } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

export default function RightToLeft({ children }) {
  const animationRef = useRef(null);
  const isInView = useInView(animationRef, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('slide');
    }
  }, [isInView]);

  return (
    <div ref={animationRef}>
      <motion.div
        variants={{
          hidden: { opacity: 0, x: 75 },
          slide: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1, delay: 0.55 }}
      >
        {children}
      </motion.div>
    </div>
  );
}
