import React, { useEffect, useState } from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Skills from './pages/Skills';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import { DATA } from './content/ProjectData';
import { SkillsData } from './content/Data';

import {
  FaCode,
  FaEnvelopeOpenText,
  FaFolderOpen,
  FaHome,
} from 'react-icons/fa';
import { FaUserGraduate } from 'react-icons/fa6';
import {
  MdOutlineNavigateBefore,
  MdOutlineNavigateNext,
  MdOutlineTimeline,
} from 'react-icons/md';
import CourseTimeline from './components/CourseTimeline';
import { timelineItems } from './content/TimelineData';

const App = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [visible, setVisible] = useState(false);

  const inputClass =
    'relative hidden md:flex gap-2 w-22 bg-[#304f61] hover:text-[#ffffff] cursor-pointer p-3 rounded-sm hover:right-[65px] duration-700';

  const handleScroll = (scrollTo) => {
    const targetSection = document.getElementById(scrollTo);
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    const sectionIds = [
      'home',
      'about',
      'skills',
      'timeline',
      'projects',
      'contact',
    ];
    sectionIds.forEach((id) => {
      const section = document.getElementById(id);
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <>
      <section id="home">
        <Home />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="skills">
        <Skills skills={SkillsData} />
      </section>
      <section id="timeline">
        <CourseTimeline timeline={timelineItems} />
      </section>
      <section id="projects">
        <Projects projects={DATA} />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <nav className={`fixed top-[8%] right-[-75px] font-normal`}>
        <ul className={`gap-1 ${visible ? 'hidden' : 'grid'}`}>
          <li
            onClick={() => handleScroll('home')}
            className={`${inputClass} ${
              activeSection === 'home' ? 'right-[60px]' : 'right-0'
            }`}
          >
            <FaHome size={20} />
            <span>Home</span>
          </li>
          <li
            onClick={() => handleScroll('about')}
            className={`${inputClass} ${
              activeSection === 'about' ? 'right-[60px]' : 'right-0'
            }`}
          >
            <FaUserGraduate size={20} />
            <span>About</span>
          </li>
          <li
            onClick={() => handleScroll('skills')}
            className={`${inputClass} ${
              activeSection === 'skills' ? 'right-[60px]' : 'right-0'
            }`}
          >
            <FaCode size={20} />
            <span>Skills</span>
          </li>
          <li
            onClick={() => handleScroll('timeline')}
            className={`${inputClass} ${
              activeSection === 'timeline' ? 'right-[60px]' : 'right-0'
            }`}
          >
            <MdOutlineTimeline size={20} />
            <span>Timeline</span>
          </li>
          <li
            onClick={() => handleScroll('projects')}
            className={`${inputClass} ${
              activeSection === 'projects' ? 'right-[60px]' : 'right-0'
            }`}
          >
            <FaFolderOpen size={20} />
            <span>Projects</span>
          </li>
          <li
            onClick={() => handleScroll('contact')}
            className={`${inputClass} ${
              activeSection === 'contact' ? 'right-[60px]' : 'right-0'
            }`}
          >
            <FaEnvelopeOpenText size={20} />
            <span>Contact</span>
          </li>
          <li onClick={handleVisibility} className="w-24 cursor-pointer p-3">
            <MdOutlineNavigateNext size={20} />
          </li>
        </ul>
        {visible && (
          <MdOutlineNavigateBefore
            size={24}
            className={`absolute bg-[#304f61] shadow-md shadow-gray-800 rounded-bl-3xl rounded-l-3xl hover:right-[75px] duration-500 ease-in-out cursor-pointer right-[70px] top-[100px]`}
            onClick={handleVisibility}
          />
        )}
      </nav>
    </>
  );
};

export default App;
