import React from 'react';
import { Link } from 'react-router-dom';
import LeftToRight from '../motions/LeftToRight';

const SocialIcon = ({ mediaURL, mediaName, children }) => {
  return (
    <Link to={`https://${mediaURL}`} target="_blank" rel="noopener noreferrer">
      <div className="text-[#2c2a2a] duration-300 hover:text-[#8a8787]">
        {children}
      </div>
    </Link>
  );
};

export default SocialIcon;
