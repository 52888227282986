import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ProjectCard(props) {
  const navigate = useNavigate();

  function handleSeeMore() {
    console.log('See More');
    console.log(props);
    navigate(`/project/${props.id}`, { state: { project: props } });
  }
  return (
    <div
      id={props.id}
      className="relative md:w-[400px] aspect-[4/3.5] gap-3  bg-[#3c3a3abd] rounded-lg flex flex-col p-2"
    >
      <img
        src={props.imgSrc}
        alt="ProjectImage"
        className="w-full h-[170px] object-contain rounded-md"
      />
      <div className="flex flex-col items-start p-2 gap-2 ">
        <h1 className="text-xl font-bold ">{props.name}</h1>
        <p className="text-justify h-[70px] overflow-hidden">
          {props.description}
        </p>
        {/* Link to ProjectDetails with project details as props */}
        <button
          // to={{
          //   pathname: `/project/${props.id}`,
          //   state: { props },
          // }}
          onClick={handleSeeMore}
          className="text-blue-500 hover:text-blue-700 duration-5"
        >
          Read more
        </button>
      </div>
    </div>
  );
}

export default ProjectCard;
