import React, { useState } from 'react';

import {
  FaAws,
  FaGitAlt,
  FaGithub,
  FaHtml5,
  FaJava,
  FaJs,
  FaLinux,
  FaNodeJs,
  FaPython,
  FaReact,
  FaSass,
  FaUnity,
  FaWordpress,
  FaVuejs,
  FaGitlab,
} from 'react-icons/fa';
import { BiLogoMongodb } from 'react-icons/bi';
import {
  SiTailwindcss,
  SiFirebase,
  SiCplusplus,
  SiBlender,
  SiCss3,
  SiNextdotjs,
  SiDotnet,
  SiFlask,
  SiScikitlearn,
  SiMysql,
  SiPowerbi,
  SiTableau,
} from 'react-icons/si';
import { TbBrandCSharp } from 'react-icons/tb';
import { GrHadoop } from 'react-icons/gr';

const SkillsItems = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  let hoverTimeout;

  const handleMouseEnter = () => {
    hoverTimeout = setTimeout(() => {
      setIsHovered(true);
    }, 1000);
  };

  const ChildComponents = {
    reactIcon: <FaReact size={56} />,
    tailwindIcon: <SiTailwindcss size={56} />,
    flaskIcon: <SiFlask size={56} />,
    awsIcon: <FaAws size={56} />,
    sassIcon: <FaSass size={56} />,
    mysqlIcon: <SiMysql size={56} />,
    powerbiIcon: <SiPowerbi size={56} />,
    tableauIcon: <SiTableau size={56} />,
    vueIcon: <FaVuejs size={56} />,
    gitlabIcon: <FaGitlab size={56} />,
    gitIcon: <FaGitAlt size={56} />,
    githubIcon: <FaGithub size={56} />,
    htmlIcon: <FaHtml5 size={56} />,
    nextIcon: <SiNextdotjs size={56} />,
    firebaseIcon: <SiFirebase size={56} />,
    sklearnIcon: <SiScikitlearn size={56} />,
    javaIcon: <FaJava size={56} />,
    nodeIcon: <FaNodeJs size={56} />,
    pythonIcon: <FaPython size={56} />,
    unityIcon: <FaUnity size={56} />,
    wordpressIcon: <FaWordpress size={56} />,
    cplusplusIcon: <SiCplusplus size={56} />,
    blenderIcon: <SiBlender size={56} />,
    cssIcon: <SiCss3 size={56} />,
    dotnetIcon: <SiDotnet size={56} />,
    csharpIcon: <TbBrandCSharp size={56} />,
    hadoopIcon: <GrHadoop size={56} />,
    linuxIcon: <FaLinux size={56} />,
    jsIcon: <FaJs size={56} />,
    mongodbIcon: <BiLogoMongodb size={56} />,
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setIsHovered(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="p-3 m-[1px] cursor-pointer w-24 h-24 md:w-36 md:h-36 bg-[#2c2a2a] items-center text-[#5c5c5c]  hover:text-[#3bbaf9] flex justify-center duration-500">
        {ChildComponents[props.icon]}
      </div>
      {isHovered && (
        <div id={props.id} className="absolute text-center px-14">
          {props.name}
        </div>
      )}
    </div>
  );
};

export default SkillsItems;
